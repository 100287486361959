import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DebounceClickDirective} from '@src/app/core/shared/directives/debounceClick/debounceClick.directive';

@NgModule({
  declarations: [DebounceClickDirective],
  imports: [CommonModule],
  exports: [DebounceClickDirective],
})
export class DebounceClickDirectiveModule {} 
