import { Directive, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appDebounceClick]'
})
export class DebounceClickDirective implements OnInit {
  @Output() debounceClick = new EventEmitter();
  private clicks = new Subject();

  constructor() { }

  /**
   * Initializes the component and sets up the initial state.
   *
   * This function is called when the component is initialized. It performs the following tasks:
   * - Subscribes to the `clicks` subject and applies the `debounceTime` operator with a duration of 500 milliseconds.
   * - Emits the debounced click event to the `debounceClick` output.
   *
   * @return {void} This function does not return a value.
   */
  ngOnInit() {
    this.clicks.pipe(
      debounceTime(500) // change this value to adjust the debounce time
    ).subscribe(e => this.debounceClick.emit(e));
  }

  /**
   * Handles the click event and emits the event to the clicks subject.
   *
   * @param {Event} event - The click event to handle.
   * @return {void} This function does not return a value.
   */
  @HostListener('click', ['$event'])
  clickEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
